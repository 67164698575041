import React, { FC, FormEvent, useState } from 'react';
// import axios from 'axios';
import BrixHeader from '../components/BrixHeader';
import BrixBreadcrumb from '../components/BrixBreadcrumb';
import BrixButton from '../components/BrixButton';
import BrixPopoverButton from '../components/BrixPopoverButton';
import BrixProgressBar from '../components/BrixProgressBar';
import BrixText from '../components/BrixText';
import { useInvoiceContext } from '../contexts/InvoiceContext';
import { HttpService } from '../services/HttpService';
import DocuSignService from '../services/DocuSignService';
import FileService from '../services/FileService';
import { makeStyles } from '@fluentui/react-components';
import SubProfileService from '../services/SubProfileService';
import SubQueueService from '../services/SubQueueService';

const useStyles = makeStyles({
  root: {
    // styling
  },
  fileUploadDiv: {
    width: '100%',
    height: '50px',
  },
  fileUploadInput: {
    paddingLeft: '100px',
  },
  errorDiv: {
    color: 'red',
  },
  fadeContainer: {
    transition: 'opacity 0.5s ease-out',
    opacity: 1,
  },
  fadeOut: {
    opacity: 0,
  },
});

const NotarizeDocumentsPage: FC = () => {

    const classes = useStyles();

    const httpService = new HttpService();
  const docuSignService = new DocuSignService();
  const fileService = new FileService();
  const subProfileService = new SubProfileService();
  const subQueueService = new SubQueueService();



  const { invoice, getInvoice, updateInvoice } = useInvoiceContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBringYourOwnLienWaiver, setIsBringYourOwnLienWaiver] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showLienWaiverOptions, /*setShowLienWaiverOptions*/] = useState<boolean>(true);
  const [fadeOut, /*setFadeOut*/] = useState<boolean>(false);

  // Function to get the Proof.com URL
  const getProofUrl = async (): Promise<void> => {
    setIsLoading(true);
    if (invoice) {
      const response = (await docuSignService.getProofUrl(
        invoice.docusignEnvelopeid,
        invoice.accessToken
      )) as string;
      invoice.proofLink = response;
      console.log('Proof link: ' + invoice.proofLink);
      await updateInvoice(invoice);
      await getInvoice(invoice.accessToken);
    }
    setIsLoading(false);
  };

  // Event handler for "Use Proof.com Notary" button
  const handleContinueToProof = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      if (invoice && !invoice.proofLink) {
        await getProofUrl();
        if (invoice.proofLink) {
          window.open(invoice.proofLink, '_self', 'noreferrer');
        } else {
          setErrorMessage('Failed to retrieve the proof link. Please try again.');
        }
      } else if (invoice?.proofLink) {
        window.open(invoice.proofLink, '_self', 'noreferrer');
      } else {
        setErrorMessage('Invoice or proof link is missing. Please try again or contact support.');
      }
    } catch (error) {
      console.error('Error in handleContinueToProof:', error);
      setErrorMessage(
        'An error occurred while processing your request. Please try again or contact BRIX.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Event handler for submitting the user's own notarized lien waiver
  const handleBringYourOwnNotarySubmitted = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    try {
      if (selectedImage !== null && invoice) {
        const formData = new FormData();
        formData.append('FormFile', selectedImage);

        let fileExtension = '';
        switch (selectedImage.type) {
          case 'application/pdf':
            fileExtension = '.pdf';
            break;
          case 'image/jpeg':
            fileExtension = '.jpg';
            break;
          case 'image/png':
            fileExtension = '.png';
            break;
          default:
            setErrorMessage('Error: please select a PDF, JPG, or PNG file to upload.');
            setSelectedImage(null);
            setIsLoading(false);
            return;
        }

        if (fileExtension.length > 0) {
          // Lien waiver upload - setup file name like "accessToken-lien-waiver.pdf"
          const uploadString = `${invoice.accessToken}-lien-waiver${fileExtension}`;

          // Upload to the FileController /upload endpoint
          await httpService.post(`api/files/upload/uploads/${uploadString}`, formData);

          // Save the upload string to the invoice
          invoice.lienWaiverObjectId = uploadString;
          invoice.subStatus = 'SUBNOTARIZED';
          await updateInvoice(invoice);
          await getInvoice(invoice.accessToken);
        }
      } else {
        alert('Please select an image first');
      }
    } catch (error) {
      setErrorMessage('Error: Failed to upload. Please try again or contact BRIX.');
      console.log('Error in handleBringYourOwnNotarySubmitted:', error);
    }
    setIsLoading(false);
  };

  const handleImageSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedImage(event.target.files !== null ? event.target.files[0] : null);
  };

  // Function to handle the fade-out transition
  const handleOptionSelected = () => {
    setIsLoading(true);
    setTimeout(() => {
      // setShowLienWaiverOptions(false);
      setIsLoading(false);
    }, 500); // Match the duration of the CSS transition
  };

  // Handler for downloading the lien waiver
  const handleDownloadLienWaiver = async () => {
    // console.log('Invoice in handleDownloadLienWaiver:', invoice);
    if (!invoice) {
      setErrorMessage('Lien waiver not available for download.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');

    try {
      // Create a DownloadRequest
      const request = {
        fileId: invoice.accessToken + "-lien.pdf",
        container: 'uploads', 
      };

     await fileService.downloadFileAsync(request);

    } catch (error) {
      console.error('Error in handleDownloadLienWaiver:', error);
      setErrorMessage('An error occurred while downloading the lien waiver. Please try again.');
    } finally {
      setIsLoading(false);
      setIsBringYourOwnLienWaiver(true);
    }
  };

    // Handler for emailing the lien waiver (to be implemented)
    const handleEmailLienWaiver = async () => {
      console.log('Emailing lien waiver...');
      setIsLoading(true);
      setErrorMessage('');
    
      try {
        if (!invoice) {
          setErrorMessage('Lien waiver not available to email.');
          return;
        }
    
        // Initialize recipientEmail
        let recipientEmail = '';
        let subcontractorData = null;
    
        // Check if the invoice has subProfile_id or subQueue_id
        if (invoice.subProfile_id) {
          // Fetch subProfile
          try {
            subcontractorData = await subProfileService.get(invoice.subProfile_id);
          } catch (error) {
            console.error('Error fetching SubProfile:', error);
            setErrorMessage('Error fetching subcontractor data.');
            return;
          }
        } else if (invoice.subQueue_id) {
          // Fetch subQueue
          try {
            subcontractorData = await subQueueService.get(invoice.subQueue_id);
          } catch (error) {
            console.error('Error fetching SubQueue:', error);
            setErrorMessage('Error fetching subcontractor data.');
            return;
          }
        } else {
          setErrorMessage('Subcontractor information is missing from the invoice.');
          return;
        }
    
        // Extract emailAddress from the subcontractorData
        if (subcontractorData && subcontractorData.emailAddress) {
          recipientEmail = subcontractorData.emailAddress;
        } else {
          setErrorMessage('Recipient email not found for the subcontractor.');
          return;
        }
    
        // Encode the recipient email to safely include it in the URL
        const encodedRecipientEmail = encodeURIComponent(recipientEmail);
    
        // Build the request URL with query parameters
        const requestUrl = `api/docusign/SendLienWaiverEmail/${invoice.invoice_id}?recipientEmail=${encodedRecipientEmail}`;
    
        // Make the HTTP GET request to the backend
        await httpService.get(requestUrl);
    
        // Show success message or notification
        // alert('Lien waiver has been emailed to you successfully.');
      } catch (error) {
        console.error('Error in handleEmailLienWaiver:', error);
        setErrorMessage('An error occurred while emailing the lien waiver. Please try again.');
      } finally {
        setIsLoading(false);
        setIsBringYourOwnLienWaiver(true);
      }
    };

  if (isLoading) {
    return <BrixProgressBar message="Loading..." />;
  }

  return (
    <div className={classes.root} data-testid="notarize-documents-container">
      <div className={`${classes.fadeContainer} ${fadeOut ? classes.fadeOut : ''}`}>
        <BrixHeader
          title="Document Signing Complete!"
          subtitle="Your Lien Waiver is ready to be notarized."
        />
        <BrixBreadcrumb subStatus={invoice?.subStatus} />
        <BrixText text="You can use Proof.com to notarize an AIA Formatted Lien Waiver online right now for free, or you can choose one of the options below." />

        {errorMessage && <div className={classes.errorDiv}>{errorMessage}</div>}

        <div>
          {/* Conditionally render the BrixPopoverButton */}
          {showLienWaiverOptions && (
            <BrixPopoverButton
              id="lien-waiver-options-button"
              title="Lien Waiver Options"
              data-testid="submit-lien-waiver-options"
              onOptionSelected={handleOptionSelected}
              onEmailLienWaiver={handleEmailLienWaiver}
              onDownloadLienWaiver={handleDownloadLienWaiver}
              onUseOwnLienWaiver={() => {
                console.log('Using own lien waiver...');
                setIsBringYourOwnLienWaiver(true);
              }}
            />
          )}

          {/* If user selects "Use Your Own Lien Waiver", show the upload form */}
          {isBringYourOwnLienWaiver && <>
            <form onSubmit={handleBringYourOwnNotarySubmitted}>
              <p>Upload a signed, notarized lien waiver (PDF or image) to continue:</p>
              <div className={classes.fileUploadDiv}>
                <input
                  className={classes.fileUploadInput}
                  type="file"
                  name="image"
                  accept="application/pdf,image/*"
                  onChange={handleImageSelection}
                  required
                  data-testid="input-file-box"
                />
              </div>
              <BrixButton
                id="proof-byon-submit-button"
                title="Submit"
                disabled={false}
                isSubmitButton={true}
                data-testid="submit-notarized-lien-waiver"
              />
            </form>
            </>
            }

          {/* Use Proof.com Notary button */}
          <form onSubmit={handleContinueToProof}>
            <BrixButton
              id="proof-continue-button"
              title="Use Proof.com Notary"
              disabled={false}
              isSubmitButton={true}
              data-testid="use-proof-notary"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default NotarizeDocumentsPage;