import { FC, useState } from 'react';
import {
  makeStyles,
  useId,
  Popover,
  PopoverTrigger,
  PopoverSurface,
} from '@fluentui/react-components';
import BrixButton from './BrixButton';

const useStyles = makeStyles({
  contentHeader: {
    marginTop: '0',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '10px',
  },
});

interface BrixPopoverButtonProps {
  id?: string;
  title: string;
  isSubmitButton?: boolean;
  disabled?: boolean;
  onClick?: () => void;

  onEmailLienWaiver?: () => void;
  onDownloadLienWaiver?: () => void;
  onUseOwnLienWaiver?: () => void;

  onOptionSelected?: () => void;
}

const BrixPopoverButton: FC<BrixPopoverButtonProps> = ({
  id,
  title,
  isSubmitButton,
  disabled,
  onClick,
  onEmailLienWaiver,
  onDownloadLienWaiver,
  onUseOwnLienWaiver,
  onOptionSelected,
  ...props
}) => {
  const styles = useStyles();
  const popoverId = useId();
  const [open, setOpen] = useState<boolean>(false);

  const handleOptionClick = (handler?: () => void) => {
    if (handler) handler();
    setOpen(false);
    if (onOptionSelected) onOptionSelected();
  };

  return (
    <Popover
      trapFocus
      open={open}
      onOpenChange={(_, data) => setOpen(data.open)}
    >
      <PopoverTrigger disableButtonEnhancement>
        <BrixButton
          id={id || 'brix-popover-button-trigger'}
          title={title}
          isSubmitButton={isSubmitButton}
          disabled={disabled}
          onClick={onClick}
          {...props}
        />
      </PopoverTrigger>

      <PopoverSurface aria-labelledby={popoverId}>
        <div>
          <h3 id={popoverId} className={styles.contentHeader}>
            Choose an Option
          </h3>
          <div className={styles.buttonGroup}>
            <BrixButton
              id="email-lien-waiver-button"
              title="Email Our Lien Waiver to You"
              onClick={() => handleOptionClick(onEmailLienWaiver)}
            />
            <BrixButton
              id="download-lien-waiver-button"
              title="Download Our Lien Waiver Here"
              onClick={() => handleOptionClick(onDownloadLienWaiver)}
            />
            <BrixButton
              id="use-own-lien-waiver-button"
              title="Use Your Own Lien Waiver"
              data-testid="submit-own-lien-waiver-option"
              onClick={() => handleOptionClick(onUseOwnLienWaiver)}
            />
          </div>
        </div>
      </PopoverSurface>
    </Popover>
  );
};

export default BrixPopoverButton;
