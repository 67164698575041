import { HttpService } from './HttpService';
const fileHttpService = new HttpService();
interface DownloadRequest {
  fileId: string;
  container: string;
}
export class FileService {
  async downloadFileAsync(request: DownloadRequest): Promise<void> {
    try {
      await fileHttpService.download('api/files/download', request);
    } catch (error) {
      console.error('Error downloading file:', error);
      throw error;
    }
  }
}
export default FileService;