import { forwardRef } from 'react';
import {
  makeStyles,
  Button
} from '@fluentui/react-components';

const useStyles = makeStyles({
  button: {
    display: 'flex',
    margin: '5px',
    float: 'right'
  }
});

interface BrixButtonProps {
  id: string;
  title: string;
  isSubmitButton?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  // Include other props if necessary
}

const BrixButton = forwardRef<HTMLButtonElement, BrixButtonProps>(({
  id,
  title,
  isSubmitButton,
  disabled,
  onClick,
  ...props
}, ref) => {
  const classes = useStyles();

  return (
    <Button
      id={id}
      ref={ref}
      type={isSubmitButton ? 'submit' : 'button'}
      appearance="secondary"
      className={classes.button}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {title}
    </Button>
  );
});

export default BrixButton;