import type { PricingTier } from '../types/pricing';
import { HttpService } from './HttpService';

// Instantiate HttpService (singleton instance)
const httpService = new HttpService();

// Define the response interface
export interface PricingSchemeResponse {
  scheme: string;
  tiers: PricingTier[];
}

// PricingService Class
export class PricingService {
  /**
   * Fetch pricing tiers for a given invoice ID.
   * @param invoiceId The ID of the invoice.
   * @returns A promise that resolves to an array of PricingTier objects.
   */
  async getPricingTiers(invoiceId: number): Promise<PricingTier[]> {
    try {
      // Make a GET request using HttpService
      const data = await httpService.get(`api/Pricing?invoiceId=${invoiceId}`) as PricingSchemeResponse;
      return data.tiers;
    } catch (error) {
      console.error("Failed to fetch pricing tiers:", error);
      throw new Error("Failed to fetch pricing tiers");
    }
  }
}

// Export a singleton instance of PricingService
export default new PricingService();