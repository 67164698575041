import { FC, FormEvent, useEffect, useState } from 'react';
import { 
    makeStyles
} from '@fluentui/react-components';
import BrixHeader from '../components/BrixHeader';
import BrixBreadcrumb from '../components/BrixBreadcrumb';
import BrixButton from '../components/BrixButton';
import { HttpService } from '../services/HttpService';
import { useInvoiceContext } from '../contexts/InvoiceContext';
import BrixProgressBar from '../components/BrixProgressBar';
import DocuSignService from "../services/DocuSignService";

const useStyles = makeStyles({
   root: {
       //color: 'blue'
   },
   errorDiv: {
    //marginTop: '10px',
    marginBottom: '20px',
    color: '#cb0043'
  },
});

const SignDocumentsPage: FC = () => {

    // styles
    const classes = useStyles();

    // states

    // url parameters
    //const { regenerate } = useParams<{ regenerate: string }>();

    // url params
    const [docusignLinkWasExpired, setDocusignLinkWasExpired] = useState<boolean>(false);
    //const [docusignWasCompleted, setDocusignWasCompleted] = useState<boolean>(false);

    // const { getReceivableSale, createReceivableSale, updateReceivableSale, deleteReceivableSale } = useReceivableSale();
    //const { receivableSale, getReceivableSale } = useReceivableSale();

    const { invoice, getInvoice, updateInvoice } = useInvoiceContext();

    const [isLoading, setIsLoading] = useState<boolean>();

    // define an httpService to use
    let httpService = new HttpService();

    // Event handler for "Continue to DocuSign" button
    const handleContinueToDocusign = async (event: FormEvent<HTMLFormElement>) : Promise<void> => {
        event.preventDefault();
        setIsLoading(true);
        if(invoice != null && invoice?.docuSignLink != null)
        {
            window.open(invoice.docuSignLink, "_self", "noreferrer");
        }
        setIsLoading(false);
    }

    const generateDocusignSigningUrl = async () : Promise<void> => {
        setIsLoading(true);
        if(invoice){
            // go out and retrieve the docusign signing url based on this accepted offer
            const docusignSigningUrl = await httpService.get(`api/docusign/SendForSignature/${invoice.invoice_id}/${invoice.invoiceAmount}`) as string;
            if(docusignSigningUrl.length > 0)
            {
                const contractObjectId = `${invoice.accessToken}-contract.pdf`;
                console.log(docusignSigningUrl);
                invoice.docuSignLink = docusignSigningUrl;
                invoice.contractObjectId = contractObjectId;
                await updateInvoice(invoice);
                await getInvoice(invoice.accessToken);
            }
        }
        setIsLoading(false);
    }

    const docuSignService = new DocuSignService();
    const advanceSubStatus = async () : Promise<void> => {
        setIsLoading(true);
        if(invoice)
        {
            // TODO: set envelopeId for the invoice here, for proof to generate its url from later
            const urlParams = new URLSearchParams(location.search);
            const envelopeId = urlParams.get("envelopeId");
            const contractObjectId = `${invoice.accessToken}-contract.pdf`;
            invoice.docusignEnvelopeid = "" + envelopeId;
            invoice.subStatus = "SUBDOCUSIGNED";
            invoice.contractObjectId = contractObjectId;
            await updateInvoice(invoice);
            await getInvoice(invoice.accessToken);
            await docuSignService.downloadLien(invoice.invoice_id);
        }
        setIsLoading(false);
    }
    
    // useEffects
    useEffect(
     () => 
        {
            if(invoice)
            {
                // if the docusign service redirected back to this page with ?event=ttl_expired in the url, 
                // regenerate the docusign url so that we get a fresh non-expired link to docusign
                const searchParams = new URLSearchParams(location.search);
                if(searchParams.has('event') && searchParams.get('event'))
                {
                    const eventName = searchParams.get('event');

                    if(eventName == 'ttl_expired')
                    {
                        setDocusignLinkWasExpired(true);
                        generateDocusignSigningUrl();
                    }
                    else if(eventName == 'signing_complete')
                    {
                        advanceSubStatus();
                    }
                }
                else 
                {
                    setDocusignLinkWasExpired(false);
                }

                if(invoice.docuSignLink == null || invoice.docuSignLink.length == 0)
                {
                    generateDocusignSigningUrl();
                }
            }          

        },
        []
    );

    if(isLoading)
    {
        return (
            <BrixProgressBar message="Loading..." />
        );
    }

    return (
    <div className={classes.root} data-testid="sign-documents-container">

        {/* { regenerate == "true" &&
                <MessageBar key="warning" intent="warning">
                <MessageBarBody>
                    <MessageBarTitle>Docusign Link Expired</MessageBarTitle>
                    We have generated a new Docusign link for you. Please try again.
                </MessageBarBody>
                </MessageBar>
        } */}

        <BrixHeader title="Company Info Submitted!" subtitle="Please proceed to sign the required documents." />
        <BrixBreadcrumb subStatus={invoice?.subStatus} />
        {/* <BrixField id="docusign-url-field" title="Docusign Link" value={receivableSaleData.documentId} disabled={false}></BrixField> */}
        {/* <BrixField id="docusign-url-field" title="Docusign Link" value={receivableSale.documentId} disabled={false}></BrixField> */}
        
        { docusignLinkWasExpired && 
            <div className={classes.errorDiv}> 
                Docusign link was expired. We re-generated a new one for you. Please try again.
            </div>
        }

        { 
            (invoice && invoice.docuSignLink != null && invoice.docuSignLink.length > 0) && 
            <form onSubmit={handleContinueToDocusign}>
                <BrixButton 
                  id="docusign-continue-button" 
                  title="Continue to DocuSign" 
                  disabled={false} 
                  isSubmitButton={true} 
                  data-testid="continue-to-docusign"
                />
            </form>
        }
    </div>
    );
}

export default SignDocumentsPage;