import { FC, useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@fluentui/react-components";
import BrixHeader from "../components/BrixHeader";
import BrixBreadcrumb from "../components/BrixBreadcrumb";
import { useInvoiceContext } from "../contexts/InvoiceContext";
import SubProfileService from "../services/SubProfileService";
import SubQueueService from "../services/SubQueueService";
import DocuSignService from "../services/DocuSignService";
import SubService from "../services/SubService";
import ReceivableSaleService from "../services/ReceivableSaleService";
import { calculateFee, DEFAULT_PRICING_TIERS } from "../utils/CalcUtils";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { Sub } from "../types/Sub";
import { SubProfile } from "../types/SubProfile";
import { SubStatus } from "../types/SubStatus";
import BrixProgressBar from "../components/BrixProgressBar";

// Import PricingService and PricingTier
import PricingService from '../services/pricingService';
import type { PricingTier } from '../types/pricing';

const useStyles = makeStyles({
  root: {
    // Add any necessary styles here
  },
  spinner: {
    margin: "20px 0",
  },
  messageBar: {
    margin: "20px 0",
  },
});

const ViewFinishSummaryPage: FC = () => {
  const classes = useStyles();
  const { invoice, getInvoice, updateInvoice, bankAccountId } = useInvoiceContext();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [pricingTiers, setPricingTiers] = useState<PricingTier[]>([]);
  const hasRunRef = useRef(false);

  // Fetch Pricing Tiers when invoice is available
  useEffect(() => {
    const fetchPricingTiers = async () => {
      if (invoice?.invoice_id && (
        invoice?.projectPricing !== 'Default' || 
        invoice?.pricing !== 'Default' || 
        invoice?.gcPricing !== 'Default'
    )) {
        try {
          const tiers = await PricingService.getPricingTiers(Number(invoice.invoice_id));
          setPricingTiers(tiers);
        } 
         catch (error) {
          console.error("Failed to fetch pricing tiers:", error);
          setError("Failed to load pricing tiers.");
        }
      }
      else {
        setPricingTiers(DEFAULT_PRICING_TIERS);
    }
    };
    fetchPricingTiers();
  }, [invoice]);

  const finalizeSubProcess = useCallback(async (): Promise<void> => {
    if (!invoice || pricingTiers.length === 0) return;

    if (
      hasRunRef.current ||
      isProcessing ||
      !invoice ||
      invoice.subStatus !== "SUBACCOUNTINFOPROVIDED"
    ) {
      console.log("Skipping finalization process", invoice?.subStatus);
      return;
    }

    setIsProcessing(true);
    setError(null);
    setSuccess(false);
    hasRunRef.current = true;

    try {
      console.log("Starting finalization process...");

      const {
        subcontractor_id,
        subProfile_id,
        subQueue_id,
        gC_id,
        saleAmount,
        contractObjectId,
        lienWaiverObjectId,
        subContractorName,
        accessToken,
      } = invoice;

      const todaysDateString = new Date().toISOString().slice(0, 10);

      // Calculate disbursed amount using dynamic pricing tiers
      const disbursedAmount = saleAmount - calculateFee(saleAmount, pricingTiers);

      const subProfileService = new SubProfileService();
      const subQueueService = new SubQueueService();
      const subService = new SubService();
      const docuSignService = new DocuSignService();
      const receivableSaleService = new ReceivableSaleService();

      let foundSubcontractorId = subcontractor_id;
      let foundSubProfileId = subProfile_id;

      // Case 1: New contact (subQueue exists)
      if (subQueue_id && subProfile_id === null && subcontractor_id === null) {
        console.log("Processing new contact...");
        const existingSubQueue = await subQueueService.get(subQueue_id);

        if (!foundSubcontractorId) {
          const newSubRecord: Partial<Sub> = {
            subcontractorName: existingSubQueue.subcontractorName,
            bankAccount_id: bankAccountId,
            address1: existingSubQueue.address1,
            address2: existingSubQueue.address2,
            city: existingSubQueue.city,
            state: existingSubQueue.state,
            zipcode: existingSubQueue.zipcode,
            ein: existingSubQueue.ein,
            emaildomain: existingSubQueue.emailAddress,
            entityType: existingSubQueue.entityType,
          };
          const returnedSubRecord = await subService.create(newSubRecord as Sub);
          foundSubcontractorId = returnedSubRecord.subContractor_id;
          console.log("New subcontractor ID: ", foundSubcontractorId);
        }

        const newSubProfile: Partial<SubProfile> = {
          subcontractor_id: foundSubcontractorId,
          gC_id,
          subcontractorName: existingSubQueue.subcontractorName,
          contactName: existingSubQueue.contactName,
          emailAddress: existingSubQueue.emailAddress,
          cellPhone: existingSubQueue.cellPhone,
          ein: existingSubQueue.ein,
          address1: existingSubQueue.address1,
          address2: existingSubQueue.address2,
          city: existingSubQueue.city,
          state: existingSubQueue.state,
          zipcode: existingSubQueue.zipcode,
          entityType: existingSubQueue.entityType,
        };
        const createdSubProfile = await subProfileService.create(newSubProfile as SubProfile);
        foundSubProfileId = createdSubProfile.subProfile_id;
        console.log("New subprofile ID: ", foundSubProfileId);

        // Delete the subqueue/Not doing this for now
        // await subQueueService.delete(subQueue_id);
        // console.log(`SubQueue with id ${subQueue_id} has been deleted.`);
        // invoice.subQueue_id = null;
      }
      // Case 2: Existing subcontractor, no subprofile
      else if (foundSubcontractorId && !foundSubProfileId) {
        console.log("Processing existing subcontractor without profile...");
        const existingSubcontractor = await subService.get(foundSubcontractorId);
        const existingSubQueue = await subQueueService.get(subQueue_id!);

        const newSubProfile: Partial<SubProfile> = {
          subcontractor_id: foundSubcontractorId,
          gC_id,
          subcontractorName: existingSubcontractor.subcontractorName,
          contactName: subContractorName,
          emailAddress: existingSubQueue.emailAddress,
          cellPhone: "",
          ein: existingSubcontractor.ein,
          address1: existingSubcontractor.address1,
          address2: existingSubcontractor.address2,
          city: existingSubcontractor.city,
          state: existingSubcontractor.state,
          zipcode: existingSubcontractor.zipcode,
        };
        const createdSubProfile = await subProfileService.create(newSubProfile as SubProfile);
        foundSubProfileId = createdSubProfile.subProfile_id;
        console.log("New subprofile: ", createdSubProfile);
        console.log("New subprofile ID: ", foundSubProfileId);
        // Delete the subqueue not doing this for now
        // await subQueueService.delete(subQueue_id!);
        // console.log(`SubQueue with id ${subQueue_id} has been deleted.`);
        // invoice.subQueue_id = null;
      }

      // Create ReceivableSale in all cases
      console.log("Creating new receivable sale...");
      if (foundSubcontractorId) {
        const newReceivableSale = {
          gC_id,
          subcontractor_id: foundSubcontractorId,
          saleAmount,
          saleDate: todaysDateString,
          invoice_id: invoice.invoice_id,
          disbursedAmount,
          contractobjectid: contractObjectId,
          lienwaiverobjectid: lienWaiverObjectId,
          subSignerName: subContractorName,
          fundedDate: todaysDateString,
          accessToken,
        };

        await receivableSaleService.create(newReceivableSale);
        console.log("New receivable sale created.");
      } else {
        throw new Error("Subcontractor ID is missing");
      }

      // Update invoice status
      const updatedInvoice = {
        ...invoice,
        subcontractor_id: foundSubcontractorId,
        subProfile_id: foundSubProfileId,
        contractObjectId: contractObjectId,
        //subQueue_id: null,
        subStatus: "SUBFINISHED" as SubStatus,
        saleStatus: "Pending",
      };
      console.log('updatedInvoice', updatedInvoice);
      await updateInvoice(updatedInvoice);
      await getInvoice(accessToken);
      await docuSignService.downloadDocuments(invoice.invoice_id);

      console.log("Finalization process completed successfully.");
      setSuccess(true);
    } catch (error) {
      console.error("Error in finalizeSubProcess:", error);
      setError(
        "An error occurred during the finalization process. Please try again or contact support."
      );
      hasRunRef.current = false; // Reset if there's an error
    } finally {
      setIsProcessing(false);
    }
  }, [invoice, getInvoice, updateInvoice, isProcessing, pricingTiers, bankAccountId]);

  useEffect(() => {
    finalizeSubProcess();
  }, [finalizeSubProcess, pricingTiers]);

  if(isProcessing) {
    return (
      <BrixProgressBar message="Loading..." />
    );
  }

  return (
    <div className={classes.root} data-testid="view-finish-summary-container">
      <BrixHeader
        title={invoice?.subStatus === "SUBFINISHED" ? "Finished!" : "Finishing Up"}
        subtitle={invoice?.subStatus === "SUBFINISHED" ? "Thank you for choosing Brix. You may now close this tab." : "Please wait while we finalize your submission."}
      />
      <BrixBreadcrumb subStatus={invoice?.subStatus || "SUBFINISHED"} />

      {error && (
        <MessageBar
          className={classes.messageBar}
          messageBarType={MessageBarType.error}
        >
          {error}
        </MessageBar>
      )}

      {success && (
        <div data-testid="finish-success-message">
          <MessageBar
            className={classes.messageBar}
            messageBarType={MessageBarType.success}
          >
            Your submission has been successfully finalized. Thank you for choosing Brix!
          </MessageBar>
        </div>
      )}
    </div>
  );
};

export default ViewFinishSummaryPage;