import { FC, FormEvent, useEffect, useState } from 'react';
import { 
    Field,
    Slider,
    makeStyles
} from '@fluentui/react-components';
import BrixHeader from '../components/BrixHeader';
import BrixBreadcrumb from '../components/BrixBreadcrumb';
import BrixField from '../components/BrixField';
import BrixButton from '../components/BrixButton';
import { useInvoiceContext } from '../contexts/InvoiceContext';
import { calculateDisbursedAmount } from '../utils/CalcUtils';
import BrixProgressBar from '../components/BrixProgressBar';

// Import PricingService and PricingTier
import PricingService from '../services/pricingService';
import type { PricingTier } from '../types/pricing';
import { DEFAULT_PRICING_TIERS } from '../utils/CalcUtils';
const useStyles = makeStyles({
   root: {
       // Add any necessary styles here
   },
   sliderField: {
      textAlign: "left",
      paddingBottom: '20px',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    errorMessage: {
      color: "red",
      marginBottom: "50px"
    }
});

const ViewOfferPage: FC = () => {

    // Styles
    const classes = useStyles();

    // States
    const [sliderValue, setSliderValue] = useState<number>(100);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pricingTiers, setPricingTiers] = useState<PricingTier[]>([]);
    const [fetchError, setFetchError] = useState<string | null>(null);

    // Contexts
    const { invoice, getInvoice, updateInvoice } = useInvoiceContext();

    // Fetch Pricing Tiers when invoice is available
    useEffect(() => {
        const fetchPricingTiers = async () => {
            if (invoice?.invoice_id && (
                invoice?.projectPricing !== 'Default' || 
                invoice?.pricing !== 'Default' || 
                invoice?.gcPricing !== 'Default'
            )) {
                try {
                    const tiers = await PricingService.getPricingTiers(Number(invoice.invoice_id));
                    setPricingTiers(tiers);
                } catch (error) {
                    console.error("Failed to fetch pricing tiers:", error);
                    setFetchError("Failed to load pricing tiers.");
                }
            }
            else {
                setPricingTiers(DEFAULT_PRICING_TIERS);
            }
        };
        fetchPricingTiers();
    }, [invoice, pricingTiers]);
    // Event handler for slider change
    const handleSliderChange = (_: any, data: { value: number }): void => {
        const newValue: number = Math.round(data.value);
        setSliderValue(newValue);
    };

    // Event handler for Accept Offer
    const handleAcceptOffer = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setIsLoading(true);

        if(invoice != null && invoice.invoiceAmount != null && sliderValue > 0 && sliderValue <= 100 && pricingTiers.length > 0) {
            try {
                // Update invoice status
                invoice.subStatus = "SUBACCEPTED";         
                const saleAmount = invoice?.invoiceAmount ? invoice.invoiceAmount * (sliderValue / 100) : 0;
                invoice.saleAmount = saleAmount;
                // Update invoice in context and backend
                await updateInvoice(invoice);
                await getInvoice(invoice.accessToken);
            } catch (error) {
                console.error("Error accepting offer:", error);
                setFetchError("An error occurred while accepting the offer.");
            }
        } else {
            setFetchError("Invalid invoice data or pricing tiers not loaded.");
        }

        setIsLoading(false);
    }

    // Event handler for Decline Offer
    const handleDeclineOffer = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setIsLoading(true);

        if(invoice) { 
            try {
                invoice.saleAmount = 0;
                invoice.subStatus = "SUBDECLINED";
                await updateInvoice(invoice);
                await getInvoice(invoice.accessToken);
            } catch (error) {
                console.error("Error declining offer:", error);
                setFetchError("An error occurred while declining the offer.");
            }
        }

        setIsLoading(false);
    }

    if(isLoading) {
        return (
            <BrixProgressBar message="Loading..." />
        );
    }

    if(fetchError) {
        return (
            <div className={classes.root} data-testid="view-offer-error">
                <BrixProgressBar message={fetchError} />
            </div>
        );
    }

    // Calculate the portion of the invoice being sold
    const portionToSell = invoice?.invoiceAmount ? invoice.invoiceAmount * (sliderValue / 100) : 0;

    return (
        <div className={classes.root} data-testid="view-offer-container">
            { (invoice != null) && <>
                <BrixHeader 
                    title="You've Received an Offer!" 
                    subtitle="You Can Have The Full Amount In Your Bank Account Tomorrow." 
                />
                
                <BrixBreadcrumb subStatus={invoice?.subStatus} />
                
                { invoice.gcName && 
                    <BrixField 
                        id="field-2" 
                        title="General Contractor Name" 
                        value={invoice?.gcName.toString()} 
                        disabled={true} 
                        data-testid="gc-name" 
                    />
                }
                { invoice.subcontractor_id && 
                    <BrixField 
                        id="field-3" 
                        title="Subcontractor Name" 
                        value={invoice?.subContractorName} 
                        disabled={true} 
                        data-testid="subcontractor-name" 
                    />
                }
                <BrixField 
                    id="field-5" 
                    title="Invoice Number" 
                    value={invoice?.invoiceNumber} 
                    disabled={true} 
                    data-testid="invoice-number" 
                />
                <BrixField 
                    id="field-6" 
                    title="Invoice Amount" 
                    value={`$${invoice?.invoiceAmount.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} 
                    disabled={true} 
                    data-testid="invoice-amount" 
                />

                <Field
                    className={classes.sliderField}
                    size="large"
                    label={`Portion to Sell: ${sliderValue}%`}
                >
                    <Slider 
                        value={sliderValue} 
                        min={1} 
                        max={100} 
                        onChange={handleSliderChange} 
                        data-testid="portion-to-sell-slider"
                    />
                </Field>

                <BrixField 
                    id="field-6" 
                    title="Invoice Sale Amount" 
                    value={`$${portionToSell.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} 
                    disabled={true} 
                    data-testid="invoice-sale-amount" 
                />

                { (portionToSell < 10000) &&
                    <p className={classes.errorMessage}>
                        Error: The minimum sale amount is $10,000. Please adjust the slider to continue.
                    </p>
                }

                {(portionToSell >= 10000) && (
                    <>
                        <BrixField
                            id="field-7"
                            title="Amount You Receive"
                            value={
                                pricingTiers.length > 0
                                    ? `$${calculateDisbursedAmount(invoice?.invoiceAmount ?? 0, (sliderValue / 100), pricingTiers).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                    : `$${0.00.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                            }
                            disabled={true}
                            data-testid="amount-received"
                        />

                        <form onSubmit={handleAcceptOffer}>
                            <BrixButton 
                                id="offer-accept-button" 
                                title="Accept" 
                                disabled={false} 
                                isSubmitButton={true} 
                                data-testid="accept-offer" 
                            />
                        </form>
                        <form onSubmit={handleDeclineOffer}>
                            <BrixButton 
                                id="offer-decline-button" 
                                title="Decline" 
                                disabled={false} 
                                isSubmitButton={true} 
                                data-testid="decline-offer" 
                            />
                        </form>
                    </>
                )}
            </>}
        </div>
    );
}

export default ViewOfferPage;