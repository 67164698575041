import type { PricingTier } from '../types/pricing';

// Custom types for better type safety
type PositiveNumber = number & { readonly brand: unique symbol };

/**
 * Validates and creates a positive number
 * @throws {Error} If number is negative or NaN
 */
function createPositiveNumber(num: number): PositiveNumber {
    if (isNaN(num) || num < 0) {
        throw new Error('Number must be positive and valid');
    }
    return num as PositiveNumber;
}

/**
 * Finds the appropriate pricing tier for a given amount
 * @throws {Error} If no tier is found or tiers are invalid
 */
function findTierForAmount(amount: number, pricingTiers: PricingTier[]): PricingTier {
    validatePricingTiers(pricingTiers);
    const tier = pricingTiers.find(t => amount <= t.less_than);
    if (!tier) {
        throw new Error(`No pricing tier found for amount: ${amount}`);
    }
    return tier;
}

/**
 * Validates pricing tiers for proper structure and ordering
 * @throws {Error} If tiers are invalid
 */
function validatePricingTiers(tiers: PricingTier[]): void {
    if (!tiers.length) {
        throw new Error('Pricing tiers cannot be empty');
    }

    const isAscendingOrder = tiers
        .slice(0, -1)
        .every((tier, index) => tier.less_than < tiers[index + 1].less_than);

    if (!isAscendingOrder) {
        throw new Error('Pricing tiers must be in ascending order by less_than value');
    }
}

// Default pricing tiers
export const DEFAULT_PRICING_TIERS: PricingTier[] = [
    { less_than: 100000, fee: 100, discount_percent: 8 },
    { less_than: 250000, fee: 100, discount_percent: 7 },
    { less_than: 100000000, fee: 250, discount_percent: 6 }
];

/**
 * Calculates Annual Percentage Rate (APR) using dynamic tiers
 * @throws {Error} If inputs are invalid
 */
export function calculateAPR(
    portion: number,
    amountReceived: number,
    daysInTerm: number,
    pricingTiers: PricingTier[] = DEFAULT_PRICING_TIERS
): number {
    try {
        const validPortion = createPositiveNumber(portion);
        const validAmount = createPositiveNumber(amountReceived);
        const validDays = createPositiveNumber(daysInTerm);

        if (validAmount > validPortion) {
            throw new Error('Amount received cannot be greater than portion');
        }

        const tier = findTierForAmount(validPortion, pricingTiers);
        const financingFeeAmount = validPortion * (tier.discount_percent / 100);
        const totalFees = financingFeeAmount + tier.fee;
        
        return Math.round((((totalFees / validAmount) / validDays) * 365 * 100) * 100) / 100;
    } catch (error) {
        throw new Error(`APR calculation failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
}

/**
 * Calculates days between two dates, including both start and end dates
 * @throws {Error} If dates are invalid
 */
export function daysBetweenDates(date1: Date, date2: Date): number {
    if (!(date1 instanceof Date) || !(date2 instanceof Date) || isNaN(date1.getTime()) || isNaN(date2.getTime())) {
        throw new Error('Invalid date inputs');
    }

    const startDate = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const endDate = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    
    const diffInMs = Math.abs(endDate.getTime() - startDate.getTime());
    return Math.floor(diffInMs / (1000 * 60 * 60 * 24)) + 1;
}

/**
 * Calculates the sale amount based on invoice amount and percentage
 * @throws {Error} If inputs are invalid
 */
export function calculateSaleAmount(
    invoiceAmount: number,
    percentageSelling: number
): number {
    try {
        const validAmount = createPositiveNumber(invoiceAmount);
        if (percentageSelling < 0 || percentageSelling > 1) {
            throw new Error('Percentage must be between 0 and 1');
        }
        
        return Math.round(validAmount * percentageSelling * 100) / 100;
    } catch (error) {
        throw new Error(`Sale amount calculation failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
}

/**
 * Calculates fee based on amount and pricing tiers
 * @throws {Error} If amount or tiers are invalid
 */
export function calculateFee(
    amount: number,
    pricingTiers: PricingTier[] = DEFAULT_PRICING_TIERS
): number {
    try {
        const validAmount = createPositiveNumber(amount);
        const tier = findTierForAmount(validAmount, pricingTiers);
        
        const financingFeeAmount = validAmount * (tier.discount_percent / 100);
        const totalFee = financingFeeAmount + tier.fee;
        
        return Math.round(totalFee * 100) / 100;
    } catch (error) {
        throw new Error(`Fee calculation failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
}

/**
 * Calculates disbursed amount after fees
 * @throws {Error} If inputs are invalid
 */
export function calculateDisbursedAmount(
    invoiceAmount: number,
    percentageSelling: number,
    pricingTiers: PricingTier[] = DEFAULT_PRICING_TIERS
): number {
    try {
        const portionOfInvoiceSelling = calculateSaleAmount(invoiceAmount, percentageSelling);
        if (portionOfInvoiceSelling === 0) return 0;
        
        const fees = calculateFee(portionOfInvoiceSelling, pricingTiers);
        return Math.round((portionOfInvoiceSelling - fees) * 100) / 100;
    } catch (error) {
        throw new Error(`Disbursed amount calculation failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
}